const sleep = ms => new Promise(r => setTimeout(r, ms));

export class Agent {
    constructor(sdkInstance) {
        this.sdkInstance = sdkInstance;
        this.config = sdkInstance.config;
        this.conversationId = null;
        this.retry = 3;
    }

    setActiveConversation(id) {
        this.conversationId = id;
        console.log(`active conversation: ${id}`)
    }

    getActiveConversation() {
        return this.conversationId;
    }

    async start(message) {
        this.newConversationWithMessage(message);
    }

    async sendAgentRequest(request,data) {
        let count = 1;
        let response;
        let responseJson;

        while(count <= this.retry) {
            let response;
            let responseJson;
            try {

                const body = {
                    request: request,
                    data: data || {}
                }
                response = await fetch(`${this.config.backendPath}/api/app/agent`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${this.config.authToken}`,
                        'Content-Type': 'application/json'
                    }, 
                    body: JSON.stringify(body)
                });
                if(!response.ok){
                    console.error(`receive error in sendAgentRequest - invalid response: not ok: ${JSON.stringify(response)}`);
                    //this.sdkInstance.triggerError('agent', request, `receive error in sendAgentRequest - invalid response: no ok`);
                    //return `receive error in sendAgentRequest - invalid response: no ok`
                } else {

                    responseJson = await response.json();
                    if(!responseJson || !responseJson.status || !responseJson.status == 'ok') {
                        console.error(`receive error in sendAgentRequest - invalid response: ${JSON.stringify(responseJson)}`);
                        //this.sdkInstance.triggerError('agent', request, `receive error in sendAgentRequest - invalid response: ${JSON.stringify(responseJson)}`);
                        //return `receive error in sendAgentRequest - invalid response: ${JSON.stringify(responseJson)}`
                    } else {
                        this.sdkInstance.triggerSuccess('agent', request, responseJson.data);
                        return responseJson.data;
                    }
                }
            }
            catch(error){
                console.error(`error on sendAgentRequest - ${error.message}`);
                //this.sdkInstance.triggerError('agent', request, error.message);
                //return error.message;
            }
            count += 1;
            sleep(2);
            console.log(`retrying fetch`);
        }
        // too many errors - give up
        this.sdkInstance.triggerError('agent', request, `receive error in sendAgentRequest - invalid response: ${JSON.stringify(response)}`);
        return;
    }

    async getAgent() { 
        return await this.sendAgentRequest('getAgent');
    }

    async getConversationList() {
        return await this.sendAgentRequest('getConversationList');
    }

    async getConversationSummary(){
        return await this.sendAgentRequest('getConversationSummary');
    }

    async getConversation(id) {
        return await this.sendAgentRequest('getConversation',{id: id});;
    }

    async newConversation() {
        await this.sendAgentRequest('newConversation');
    }

    async activateConversation(conversationId) {
        this.setActiveConversation(conversationId);
        await this.sendAgentRequest('activateConversation',{id: conversationId});
    }

    async deleteConversation(conversationId){
        await this.sendAgentRequest('deleteConversation',{id: conversationId});
    }

    async addMessageToConversation(message) {
        await this.sendAgentRequest('addMessageToConversation',{
            message: message,
            prompt: true,
            wait: true
        })
    }

    async newConversationWithMessage(message) {
        await this.sendAgentRequest('newConversationWithMessage',{
            message: message,
            prompt: true,
            wait: true
        })
    }

    async startBatch(name, user_id) {
        const batch = await this.sendAgentRequest('startBatch',{
            name: name,
            user_id: user_id
        })
        return batch.id;
    }
    async addMessageFeedback(message_id, feedback) {
        const batch = await this.sendAgentRequest('addMessageFeedback',{
            id: message_id,
            feedback: feedback
        })
    }

    async endBatch(batch_id) {
        await this.sendAgentRequest('endBatch', {
            id: batch_id
        })
    }

    async setBatch(params) {
        await this.sendAgentRequest('setBatch', params);
    }

    async getBatch(name) {
        return await this.sendAgentRequest('getBatch', {
            name: name
        });
    }
    async setCategory(category,category_title) {
        await this.sendAgentRequest('setCategory',{
            category: category,
            category_title: category_title
        });
    }
    
}