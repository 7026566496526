import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import AI from "../../assets/AI.png";
import dayjs from "dayjs";
import { BiError } from "react-icons/bi";
import Typed from "typed.js";
import InputArea from "./InputArea";
import { theme } from "../Theme";

const ChatMessage = ({
	setMessages,
	chat,
	handleChat,
	submitButtonDisabled,
	combinedText,
	setCombinedText,
	isLoading,
	setAssistantChat,
	error,
}) => {
	const messagesContainerRef = useRef(null);
	const el = useRef(null);
	const [list, setList] = useState([]);

	const today = new Date();
	const dateOptions = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		seconds: "numeric",
	};

	useEffect(() => {
		const id = setTimeout(() => {
			if (!list.length) {
				window.sdkInstance.file.getFaqList().then((list) => {
					setList(list);
				});
			}
		}, 1000);

		return () => {
			clearTimeout(id);
		};
	}, [list.length]);

	useEffect(() => {
		const text = list.pacifiers;
		if (el.current) {
			const typed = new Typed(el.current, {
				strings: text,
				typeSpeed: 30,
				parseHtml: true,
				fadeOut: true,
				backSpeed: 30,
				backDelay: 2000,
				cursorChar: "●",
				autoInsertCss: true,
				showCursor: true,
				loop: true,
				loopCount: Infinity,
			});

			return () => {
				typed.destroy();
			};
		}
	}, [list.pacifiers, isLoading]);

	return (
		<Div>
			<MessagesContainer ref={messagesContainerRef}>
				<Greeting>
					<img src={AI} alt="assistant" />
					<Messages>
						<p className="date">{today.toLocaleString("en-US", dateOptions)}</p>
						<p className="welcome">{list.welcome}</p>
					</Messages>
				</Greeting>
				{combinedText.map((message, i) => {
					const isUser = message.type === "user";
					const isAssistant = message.type === "assistant";
					const isLastAssistant = i === combinedText.length - 1 && isAssistant;
					const formattedTime = dayjs(message.created_at).format(
						"h:mm:ss A, D MMM"
					);

					return (
						<div key={i}>
							{isUser && (
								<UserMessageContainer>
									<UserMessage style={{ color: "var(--text-200)" }}>
										<Timestamp>{formattedTime}</Timestamp>
										<p>{message?.content}</p>
									</UserMessage>
									<GradientImage />
								</UserMessageContainer>
							)}
							{isAssistant && (
								<AgentMessage style={{ color: "var(--text-100)" }}>
									<AssistantImage src={AI} alt="assistant" />
									<div>
										<Timestamp>{formattedTime}</Timestamp>
										{isLastAssistant && isLoading ? (
											<div>
												{error ? (
													<p className="error">
														{error} <ErrorIcon />
													</p>
												) : (
													list.pacifiers.length > 0 && (
														<>
															<p>{list.pacifierHeader}</p>
															<span
																ref={el}
																style={{
																	fontSize: "0.9rem",
																	color: "var(--accent-100)",
																}}
															/>
														</>
													)
												)}
											</div>
										) : (
											<>
												{message?.content
													.split("\n")
													.map((paragraph, index) => (
														<p key={index}>{paragraph}</p>
													))}
											</>
										)}
									</div>
								</AgentMessage>
							)}
						</div>
					);
				})}
			</MessagesContainer>
			<Bottom>
				<InputArea
					setCombinedText={setCombinedText}
					submitButtonDisabled={submitButtonDisabled}
					setMessages={setMessages}
					handleChat={handleChat}
					list={list}
					setAssistantChat={setAssistantChat}
					chat={chat}
				/>
			</Bottom>
		</Div>
	);
};

export default ChatMessage;

const Div = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	width: 60vw;
	height: 90%;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 80vw;
	}
`;

const Greeting = styled.div`
	color: var(--text-100);
	display: flex;
	align-items: flex-start;
	margin-top: 2rem;

	img {
		height: 4vh;
		width: 4vh;
		border-radius: 0.4rem;

		@media (max-width: ${theme.breakpoints.sm}) {
			height: 5vh;
			width: 5vh;
		}
	}
`;

const Messages = styled.div`
	padding-left: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;

	.date {
		font-size: 0.8rem;
		line-height: 1.2;
		margin-bottom: 0.9rem;
	}

	.welcome {
		font-size: 0.9rem;
		line-height: 1.5;
		margin-bottom: 0.9rem;
	}
`;

const Timestamp = styled.span`
	font-size: 0.8rem;
	color: var(--text-200);
	margin-bottom: 0.9rem;
`;

const MessagesContainer = styled.div`
	width: 60vh;
	height: 70vh;
	margin: 1.5rem auto;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const UserMessage = styled.div`
	color: var(--text-300);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	p {
		font-size: 0.9rem;
		line-height: 1.2;
		margin-bottom: 0.9rem;
	}
`;

const UserMessageContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 2rem;
`;

const GradientImage = styled.div`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
	position: relative;
	background: linear-gradient(5deg, lightblue, turquoise);

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 5vh;
		width: 7vh;
	}
`;

const AgentMessage = styled.div`
	color: var(--text-100);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: row;

	p {
		font-size: 0.9rem;
		line-height: 1.5;
		margin: 1rem 0 0.9rem 0;
	}

	img {
		height: 5vh;
		width: 5vh;
	}

	.error {
		color: var(--text-600);
		background-color: var(--bg-500);
		padding: 0.6rem;
		border-radius: 0.4rem;
		margin-top: 0.7rem;
		font-size: 1rem;
	}
`;

const ErrorIcon = styled(BiError)`
	color: red;
	margin-left: 4px;
	vertical-align: middle;
	font-size: 1.3rem;
`;

const AssistantImage = styled.img`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
	margin-right: 1rem;
`;

const Bottom = styled.div`
	position: fixed;
	bottom: 0;

	@media (max-width: ${theme.breakpoints.sm}) {
		/* width: 55vh; */
		/* left: 0;
		right: 0; */
	}

	/* @media (max-width: ${theme.breakpoints.md}) {
		width: 54vh;
	} */
`;
