import React from "react";
import styled from "styled-components";
import { LuCircleDashed } from "react-icons/lu";
import { FaCheck } from "react-icons/fa6";

const Checkbox = ({ checked, onChange, currentTheme }) => (
	<CheckboxContainer>
		<HiddenCheckbox type="checkbox" checked={checked} onChange={onChange} />
		<StyledCheckbox checked={checked}>
			{checked ? (
				<FaCheck
					style={{
						color: "white",
						fontSize: "0.8rem",
						marginTop: "0.1rem",
						marginLeft: "0.15rem",
					}}
				/>
			) : (
				<LuCircleDashed style={{ fontSize: "1.2rem" }} />
			)}
		</StyledCheckbox>
		{currentTheme === "light" ? (
			<div className="second">
				<p>Switch to Dark Mode</p>
			</div>
		) : (
			<div className="second">
				<p>Switch to Light Mode</p>
			</div>
		)}
	</CheckboxContainer>
);

export default Checkbox;

const CheckboxContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	vertical-align: middle;
`;

const StyledCheckbox = styled.div`
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border-radius: 1rem;
	color: ${(props) => (props.checked ? "purple" : "var(--accent-color)")};
	transition: color 0.3s;
	background-color: ${(props) =>
		props.checked ? "purple" : "var(--accent-color)"};

	svg {
		fill: var(--text-color);
		display: block;
	}
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	position: absolute;
	left: 2.2rem;
	top: 22.3rem;
	opacity: 0;
	cursor: pointer;

    @media screen and (max-width: 937px) {
		
	}

	@media screen and (max-width: 900px) {
		
	}

	@media screen and (max-width: 1100px) {
	top: 21.9rem;
		
	}
`;
