import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SDK } from "../sdk/sdk/sdk.js";
import Dashboard from "./dashboard/Dashboard.js";
import LandingPage from "./LandingPage/Landing.js";
import { StyleSheetManager } from "styled-components";


const App = () => {
	const navigate = useNavigate();

	useEffect(() => {
		async function startSdk() {
			console.log(`location: ${JSON.stringify(window.location)}`);
			const queryParams = window.location;
			window.sdkInstance = new SDK({
				backendPath: "",
				queryParams: queryParams,
			});

			window.sdkInstance.registerSuccessCallback(
				["auth"],
				async function (_category, name, data) {
					switch (name) {
						case "login": {
							window.sdkInstance.config.authToken = data.authToken;
							window.sdkInstance.config.userData = data.userData;
							console.log(`login callback: ${JSON.stringify(data)}`);
							const response = window.sdkInstance.config;

							if (response) {
								localStorage.setItem("config", JSON.stringify(response));
									navigate("/dashboard");
							
							} else {
								console.log("Authentication failed");
							}
							break;
						}
						default: {
							console.log(`Unhandled auth success callback: ${name}`);
							break;
						}
					}
				}
			);
			window.sdkInstance.registerErrorCallback(
				["auth"],
				async function (_category, name, data) {
					switch (name) {
						case "login": {
							window.sdkInstance.logout();
							navigate("/");
							console.log(`showInvalidLoginMessage: ${JSON.stringify(data)}`);
							break;
						}
						default: {
							window.sdkInstance.logout();
							console.log(`Unhandled auth success callback: ${name}`);
							break;
						}
					}
				}
			);

			window.sdkInstance.registerSuccessCallback(
				["agent"],
				async function (_category, name, data) {
					switch (name) {
						case "newConversationWithMessage":
							window.sdkInstance.agent.setActiveConversation(
								data.conversationId
							);
							break;
						default: {
							console.log(`Unhandled auth success callback: ${name}`);
							break;
						}
					}
				}
			);

			await window.sdkInstance.start();
		}

		startSdk();
	}, [navigate]);

	return (
		<>
			<StyleSheetManager shouldForwardProp={(prop) => prop !== "isVisible"}>
				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="/dashboard" element={<Dashboard />} />
				</Routes>
			</StyleSheetManager>
		</>
	);
};

export default App;
