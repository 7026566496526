import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ChatHistoryId from "./ChatHistoryId";

const ChatHistory = ({ closeModal }) => {
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [history, setHistory] = useState([]);
	const [modalWidth, setModalWidth] = useState("75vw");
	const [modalHeight, setModalHeight] = useState("80vh");
	const [selectedConversation, setSelectedConversation] = useState(null);

	const closeModalAndReset = () => {
		closeModal();
		setIsModalOpen(false);
		setSelectedConversation(null);
	};

	const handleConversationClick = async (conversationId) => {
		try {
			const convoById = await window.sdkInstance.agent.getConversation(
				conversationId
			);
			setSelectedConversation(convoById);
		} catch (error) {
			console.error(error);
		}
	};

	const summary = async () => {
		try {
			const convsersationSummary =
				await window.sdkInstance.agent.getConversationSummary();
			setHistory(convsersationSummary.conversations);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		summary();
	}, []);

	const formatDate = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);
		const date = dateTime.toDateString();
		const time = dateTime.toTimeString().split(" ")[0];
		return { date, time };
	};

	const updateModalSize = () => {
		if (window.innerWidth <= 1730) {
			setModalWidth("70vw");
			setModalHeight("90vh");
		}
		if (window.innerWidth <= 1560) {
			setModalWidth("65vw");
			setModalHeight("90vh");
		}
		if (window.innerWidth <= 1400) {
			setModalWidth("65vw");
		}
		if (window.innerWidth <= 1300) {
			setModalWidth("66vw");
		}
		if (window.innerWidth <= 1290) {
			setModalWidth("60vw");
		}
		if (window.innerWidth <= 1240) {
			setModalWidth("60vw");
		}
		if (window.innerWidth <= 1040) {
			setModalWidth("67vw");
		}
	};

	useEffect(() => {
		updateModalSize();
		window.addEventListener("resize", updateModalSize);

		return () => {
			window.removeEventListener("resize", updateModalSize);
		};
	}, []);

	const reversedMessages = [...history].reverse();

	return (
		<>
			{isModalOpen && (
				<ModalOverlay onClick={closeModalAndReset}>
					<ModalContent
						onClick={(e) => e.stopPropagation()}
						width={modalWidth}
						height={modalHeight}
					>
						<CloseButton onClick={closeModalAndReset}>
							<IoIosCloseCircleOutline />
						</CloseButton>
						{selectedConversation ? (
							<ChatHistoryId
								conversation={selectedConversation}
								goBack={() => setSelectedConversation(null)}
							/>
						) : (
							<Ul>
								{reversedMessages.map((item) => {
									const titleLimit = 40;
									const truncatedTitle =
										item.top_message?.content?.slice(0, titleLimit) +
										(item.top_message?.content?.length > titleLimit
											? "..."
											: "");

									return (
										<div key={item.id}>
											{item.top_message?.type === "user" && (
												<li onClick={() => handleConversationClick(item.id)}>
													<ContentWrapper>
														<p className="title">{truncatedTitle}</p>
														<Content>
															<p>{formatDate(item.created_at).date}</p>
															<p>{formatDate(item.created_at).time}</p>
														</Content>
													</ContentWrapper>
												</li>
											)}
										</div>
									);
								})}
							</Ul>
						)}
					</ModalContent>
				</ModalOverlay>
			)}
		</>
	);
};

export default ChatHistory;

const ModalOverlay = styled.div`
	position: fixed;
	top: 5rem;
	left: 0;
	max-width: ${(props) => props.width};
	height: 90vh;
	background: var(--bg-200);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
`;

const ModalContent = styled.div`
	overflow-y: scroll;
	background: var(--bg-200);
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	margin: 3rem;
`;

const Ul = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	li {
		border: solid var(--text-100) 0.5px;
		margin: 1.4rem;
		height: 5rem;
		width: 55vw;
		border-radius: 0.6rem;
		list-style: none;
		padding: 1rem;
	}
`;

const ContentWrapper = styled.div`
	.title {
		font-weight: 900;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 2.5rem;
`;

const CloseButton = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.6rem;
	color: var(--text-100);
`;
