import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";
import { theme } from "../Theme";

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const login = await window.sdkInstance.login(username, password);
			if (login) {
				console.log(login);
			} else {
				setLoginError(
					"Authentication failed, please check credentials and try again"
				);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Div>
			<h1>Login</h1>
			{loginError && <ErrorMessage>{loginError}</ErrorMessage>}
			<form onSubmit={handleSubmit}>
				<FormContainer>
					<Input
						type="text"
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<Input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</FormContainer>
				<Bottom>
					<Button type="submit">
						<p>login</p>
					</Button>
				</Bottom>
			</form>
			<img src={Logo} alt="Logo" className="img" />
		</Div>
	);
};

export default Login;

const Div = styled.div`
	background-color: #fdfffc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.img {
		position: absolute;
		bottom: 0;
		width: 14vh;
	}

	h1 {
		font-weight: bolder;
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
`;

const Input = styled.input`
	border: none;
	outline: none;
	background-color: #d9d9d9;
	border-radius: 0.3rem;
	padding: 0.7rem;
	margin: 1rem;
	width: 25rem;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		width: 17rem;
	}
	
`;

const ErrorMessage = styled.span`
	color: var(--text-500);
	background-color: rgba(255, 99, 71, 0.3);
	border-left: solid red 3px;
	padding: 0.5rem;
	margin-top: 0.5rem;
	display: inline-block;
	width: 25vh;
	text-align: center;
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Button = styled.button`
	width: 10rem;
	border-radius: 0.3rem;
	border: none;
	outline: none;
	background-color: #02ffb3;
	font-size: 1rem;
	margin: 1rem;
	cursor: pointer;
`;
