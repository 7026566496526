import React, { useEffect, useState } from "react";
import Directory from "./Directory";
import Navbar from "./Navbar";
import Chat from "./Chat";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ThemeProvider from "../ThemeContext";
import GlobalStyle from "../GlobalStyle.js";

const Dashboard = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const config = localStorage.getItem("config");
		if (!config) {
			navigate("/");
		}

		const loadingTimeout = setTimeout(() => {
			setLoading(false);
		}, 4000);
		return () => clearTimeout(loadingTimeout);
	}, [navigate]);

	return (
		<ThemeProvider>
			<GlobalStyle />
			{loading && (
				<LoadingOverlay>
					<p>Loading .. </p>
				</LoadingOverlay>
			)}
			<Div>
				<Main>
					<Navbar />
					<Chat />
				</Main>
				<Directory />
			</Div>
		</ThemeProvider>
	);
};

export default Dashboard;

const Div = styled.div`
	display: flex;
	font-family: "Inter", sans-serif;
	background-color: var(--bg-200);
`;

const Main = styled.div`
	background-color: var(--bg-200);
	height: 100vh;
	width: 100%;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);
`;

const LoadingOverlay = styled.div`
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--bg-200);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: green;
	font-family: "Inter", sans-serif;
`;
