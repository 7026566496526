import React, { createContext, useContext, useState, useEffect } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import {
	// landingPageTheme,
	dashboardDarkTheme,
	dashboardLightTheme,
} from "./Theme";

const ThemeContext = createContext();

export const useTheme = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error("useTheme must be used within a ThemeProvider");
	}
	return context;
};

const ThemeProvider = ({ children }) => {
	const [currentTheme, setCurrentTheme] = useState("dark");

	useEffect(() => {
		if (currentTheme === "light") {
			document
				.querySelector("html")
				.setAttribute("data-theme", "light-dashboard");
		} else {
			document.querySelector("html").removeAttribute("data-theme");
		}
	}, [currentTheme]);

	const toggleTheme = () => {
		const newTheme = currentTheme === "dark" ? "light" : "dark";
		setCurrentTheme(newTheme);
		localStorage.setItem("theme", newTheme);
	};

	const theme =
		currentTheme === "light"
			? dashboardLightTheme
			: currentTheme === "dark"
			? dashboardDarkTheme
			: currentTheme === "dark";

	return (
		<ThemeContext.Provider value={{ currentTheme, toggleTheme }}>
			<StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
		</ThemeContext.Provider>
	);
};

export default ThemeProvider;
