import React, { useState } from "react";
import styled from "styled-components";
import ChatHistory from "./ChatHistory";
import { theme } from "../Theme";
import { PiQuestionLight } from "react-icons/pi";
import { BsLightningCharge } from "react-icons/bs";

const NavItems = () => {
	const [isChatHistoryModalOpen, setIsChatHistoryModalOpen] = useState(false);

	const openChatHistoryModal = () => {
		setIsChatHistoryModalOpen(true);
	};

	const closeChatHistoryModal = () => {
		setIsChatHistoryModalOpen(false);
	};
	return (
		<NavMenu>
			<NavItem>
				<BsLightningCharge
					style={{
						fontSize: "1.1rem",
						marginLeft: "0.2rem",
						color: "var(--accent-100)",
					}}
				/>
				<button className="upgrade" onClick={openChatHistoryModal}>
					Chat History
				</button>
				{isChatHistoryModalOpen && (
					<ChatHistory closeModal={closeChatHistoryModal} />
				)}
			</NavItem>
			<NavItem>
				<PiQuestionLight
					style={{
						fontSize: "1.2rem",
						marginTop: "0.1rem",
						marginLeft: "0.5rem",
						color: "var(--text-300)",
					}}
				/>
				<a
					href="mailto:supports@bnefitsbuddy.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p className="help">Help</p>
				</a>
			</NavItem>
		</NavMenu>
	);
};

export default NavItems;

const NavMenu = styled.div`
	display: flex;

	@media (max-width: ${theme.breakpoints.lg}) {
		flex-direction: column;
	}
`;

const NavItem = styled.div`
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	font-size: 0.9rem;

	.upgrade {
		color: var(--accent-100);
		font-size: 0.9rem;
	}

	.grey {
		color: var(--text-200);
	}

	.help {
		color: var(--text-300);
		cursor: pointer;
	}

	button {
		background-color: transparent;
		color: var(--text-200);
		border: none;
		cursor: pointer;
	}

	@media (max-width: ${theme.breakpoints.lg}) {
		padding: 0.5rem 0;
		gap: 0.5rem;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		padding: 0.5rem 0;
		gap: 0.5rem;
	}
`;
