import React from "react";
import styled from "styled-components";
import TypedText from "./Typed";
import Login from "./Login";
import { theme } from "../Theme";

const LandingPage = () => {
	return (
		<MainDiv>
			<LeftContainer>
				<TypedText />
			</LeftContainer>
			<RightContainer>
				<Login />
			</RightContainer>
		</MainDiv>
	);
};

export default LandingPage;

const MainDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 100vh;
	font-family: "Inter", sans-serif;
`;

const LeftContainer = styled.div`
	flex: 1;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		display: none;
	}

	@media screen and (max-width: ${theme.breakpoints.md}) {
		display: none;
	}
`;

const RightContainer = styled.div`
	flex: 1;
`;
