import React from "react";
import styled from "styled-components";
import AI from "../../assets/AI.png";
import { theme } from "../Theme";
import NavItems from "./NavItems";
import UserItems from "./UserItems";

const Navbar = () => {
	return (
		<Nav>
			<Logo>
				<img src={AI} alt="AI" className="image" />
				<div className="title">
					<h4>Benefits Buddy </h4>
					<p> ● Powered by Lockton</p>
				</div>
			</Logo>
			<Display>
				<NavItems />
				<UserItems />
			</Display>
		</Nav>
	);
};

export default Navbar;

const Nav = styled.nav`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--bg-300);
	padding: 1rem;
	color: var(--text-100);
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);

	@media (${theme.breakpoints.sm}) {
		display: none;
	}
`;

const Logo = styled.div`
	display: flex;

	.title {
		margin-left: 0.4rem;
	}

	.image {
		border-radius: 0.4rem;
		height: 5vh;
		width: 5vh;
	}

	h4 {
		font-weight: 400;
	}

	p {
		font-size: 0.8rem;
		margin-top: 0.3rem;
		color: var(--accent-100);
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		h4 {
			font-size: 1rem;
		}

		p {
			font-size: 0.6rem;
		}
	}
`;

const Display = styled.div`
	display: flex;
	@media (max-width: ${theme.breakpoints.lg}) {
		display: none;
	}
`;
