import React, { useState, useEffect } from "react";
import ChatMessage from "./ChatMessage";
import dayjs from "dayjs";

const Chat = () => {
	const [isLoading, setIsloading] = useState(false);
	const [messages, setMessages] = useState("");
	const [combinedText, setCombinedText] = useState([]);
	const [assistantChat, setAssistantChat] = useState([]);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		clearChat();
	}, []);

	const clearChat = () => {
		setAssistantChat([]);
		localStorage.removeItem("userChat");
		localStorage.removeItem("assistantChat");
	};

	const handleChat = async (message) => {
		try {
			const msg = typeof message === "string" ? String(message) : messages;

			setSubmitButtonDisabled(true);
			const agent = window.sdkInstance.agent;

			setCombinedText((prevCombinedText) => [
				...prevCombinedText,
				{
					type: "user",
					content: msg,
					created_at: dayjs().format("h:mm:ss A, D MMM"),
				},
				{ type: "assistant", content: "", loading: true },
			]);

			if (!msg.trim()) {
				return;
			}

			setIsloading(true);
			if (!agent.getActiveConversation()) {
				setMessages("");
				await agent.newConversationWithMessage(msg);
			} else {
				await agent.addMessageToConversation(msg);
			}

			await getRunCompletion(msg);
		} finally {
			setSubmitButtonDisabled(false);
		}
	};

	const getRunCompletion = async () => {
		try {
			for (let i = 0; i < 60; i++) {
				console.log(`getRunCompletion trying ${i} time`);
				await sleep(2000);
				const response = await window.sdkInstance.agent.getAgent();

				if (response && response.agent) {
					if (response.agent.run_status === "completed") {
						const convId = window.sdkInstance.agent.getActiveConversation();
						const messageResponse =
							await window.sdkInstance.agent.getConversation(convId);
						const newMessages = messageResponse.messages;

						if (newMessages && newMessages.length >= 2) {
							setCombinedText((prevCombinedText) => [
								...prevCombinedText.slice(0, -2),
								newMessages[newMessages.length - 2],
								newMessages[newMessages.length - 1],
							]);

							console.log(`combinedText: ${JSON.stringify(combinedText)}`);

							localStorage.setItem("allMessages", JSON.stringify(combinedText));
							setIsloading(false);
							return true;
						}
					}
				}
			}
			throw new Error("Exceeded maximum attempts (50) to get run completion.");
		} catch (error) {
			console.error("Error in getRunCompletion:", error);
			setError(
				"Network issues, need to refresh, prompt not related to benefits, etc."
			);
		}
	};

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	return (
		<>
			<ChatMessage
				isLoading={isLoading}
				combinedText={combinedText}
				setCombinedText={setCombinedText}
				chat={messages}
				setMessages={setMessages}
				handleChat={handleChat}
				submitButtonDisabled={submitButtonDisabled}
				setAssistantChat={setAssistantChat}
				assistantChat={assistantChat}
				error={error}
			/>
		</>
	);
};

export default Chat;
