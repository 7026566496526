import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { PiNavigationArrowFill } from "react-icons/pi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TbPencil } from "react-icons/tb";
import { VscSparkle } from "react-icons/vsc";
import { theme } from "../Theme";

const InputArea = ({
	setCombinedText,
	submitButtonDisabled,
	setMessages,
	handleChat,
	list,
	setAssistantChat,
	chat,
}) => {
	const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
	const [isListSet, setIsListSet] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const textareaRef = useRef(null);
	const messagesContainerRef = useRef(null);

	console.log(isTyping);

	const newConversation = async () => {
		try {
			const newConvo =
				await window.sdkInstance.agent.newConversationWithMessage(chat);
			console.log(newConvo);
			setCombinedText([]);
			setIsSuggestionsVisible(false);
		} catch (error) {
			console.error(error);
		}
	};

	const closeModal = () => {
		setIsSuggestionsVisible(false);
	};

	const handleTyping = () => {
		setIsTyping(true);
		setIsSuggestionsVisible(false);
	};

	const autoResize = (textarea) => {
		textarea.style.height = "auto";
		textarea.style.height = textarea.scrollHeight + "px";
	};

	useEffect(() => {
		const textarea = textareaRef.current;
		const handleKeyPress = (e) => {
			if (e.key === "Enter" && !submitButtonDisabled) {
				e.preventDefault();
				setMessages("");
				handleChat(textarea.value);
			}
		};
		if (textarea) {
			textarea.addEventListener("keydown", handleKeyPress);
			textarea.addEventListener("input", handleTyping);
			textarea.value = "";
			setMessages("");
		}
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop =
				messagesContainerRef.current.scrollHeight;
		}
		if (isListSet) {
			setIsListSet(false);
		}

		return () => {
			if (textarea) {
				textarea.removeEventListener("keydown", handleKeyPress);
				setMessages("");
			}
		};
	}, [handleChat, isListSet, setMessages, submitButtonDisabled]);

	const handleSuggestions = async (suggestionList) => {
		try {
			setIsListSet(true);
			setIsSuggestionsVisible(true);
			setAssistantChat({ type: "suggestions", content: suggestionList });
		} catch (error) {
			console.error(error);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setMessages(suggestion);
		setIsSuggestionsVisible(false);
		handleChat(suggestion);
	};

	return (
		<div>
			{isSuggestionsVisible && list && (
				<List>
					<p className="agent-suggestions">{list.suggestionTitle}</p>
					<CloseButton onClick={closeModal}>
						<IoIosCloseCircleOutline />
					</CloseButton>
					<SuggestionsContainer>
						<ul>
							{list.suggestions.map((suggestion, index) => (
								<li
									key={index}
									onClick={() => {
										handleSuggestionClick(suggestion);
										setIsSuggestionsVisible(false);
									}}
								>
									{suggestion}
								</li>
							))}
						</ul>
					</SuggestionsContainer>
				</List>
			)}
			<Input>
				<Top>
					<button
						value={chat}
						onClick={handleSuggestions}
						onChange={(e) => {
							setMessages(e.target.value);
						}}
						className="suggestions"
					>
						<Sparkle />
						Suggestions
					</button>
					<button
						onClick={newConversation}
						value={chat}
						onChange={() => {
							setMessages("");
						}}
						className="dialog"
					>
						<p>
							<Pencil />
							New Conversation
						</p>
					</button>
				</Top>
				<Text>
					<Textarea
						id="autoresizing"
						ref={textareaRef}
						defaultValue={chat}
						tabIndex={0}
						rows={0}
						placeholder="What questions do you have..."
						onChange={(e) => {
							setMessages("");
							autoResize(e.target);
						}}
					/>
					<Icons>
						<button
							type="submit"
							onClick={handleChat}
							disabled={submitButtonDisabled}
						>
							<ArrowIcon disabled={submitButtonDisabled} />
						</button>
					</Icons>
				</Text>
				<Enter>
					<p>Return or Enter to send</p>
				</Enter>
			</Input>
		</div>
	);
};

export default InputArea;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;
	background-color: var(--bg-200);

	.suggestions,
	.dialog {
		display: flex;
		align-items: center;
		color: var(--text-200);
		padding: 0.3rem;
		border-radius: 0.4rem;
		outline: none;
		border: none;
	}

	.dialog {
		background-color: transparent;
		cursor: pointer;
	}

	.suggestions {
		cursor: pointer;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 40vh;
	}
`;

const Sparkle = styled(VscSparkle)`
	margin-right: 0.4rem;
	transform: rotate(90deg);
	font-size: 1rem;
`;

const Pencil = styled(TbPencil)`
	font-size: 0.95rem;
	position: relative;
	top: 0.1rem;
`;

const SuggestionsContainer = styled.div`
	flex-wrap: wrap;
	overflow-y: scroll;
	display: flex;
	justify-content: center;
	align-items: center;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	ul {
		list-style: none;
		padding: 0.3rem;
		width: 45vh;

		li {
			cursor: pointer;
			margin: 0.8rem;
			padding: 0.5rem;
			border: 1px solid var(--text-100);
			border-radius: 0.5rem;
			color: var(--text-100);
			width: 40vh;

			@media screen and (max-width: ${theme.breakpoints.lg}) {
				width: 27vh;
			}

			@media (max-width: ${theme.breakpoints.sm}) {
				width: 23vh;
				font-size: 0.8rem;
				margin-top: 1rem;
			}
		}

		@media (max-width: ${theme.breakpoints.lg}) {
			width: 30vh;
		}

		@media (max-width: ${theme.breakpoints.sm}) {
			width: 25vh;
		}
	}
`;

const List = styled.div`
	width: 66vh;
	height: 53vh;
	margin: 1rem;
	background-color: var(--bg-400);
	border-radius: 0.7rem;
	display: flex;
	flex-direction: column;

	.agent-suggestions {
		color: var(--text-100);
		padding-top: 0.9rem;
		padding-left: 1rem;
		text-align: left;
		width: 50vh;

		@media screen and (max-width: ${theme.breakpoints.lg}) {
			width: 40vh;
		}

		@media (max-width: ${theme.breakpoints.sm}) {
			width: 30vh;
			font-size: 0.8rem;
		}
	}

	@media screen and (max-width: ${theme.breakpoints.lg}) {
		width: 50vh;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 40vh;
	}
`;

const CloseButton = styled.button`
	position: absolute;
	top: 2rem;
	right: 2rem;
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1.5rem;
	color: var(--text-100);
`;

const Input = styled.div`
	margin: 1rem 0;
	padding: 1rem;
	border-color: hsla(0, 0%, 100%, 0.2);
	z-index: 10;
`;

const Text = styled.div`
	background-color: var(--bg-100);
	border-color: rgba(32, 33, 35, 0.5);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4rem 0;
	position: relative;

	@media screen and (max-width: ${theme.breakpoints.lg}) {
		width: 50vh;
	}

	@media screen and (max-width: ${theme.breakpoints.xl}) {
		width: 43vh;
	}


	@media (max-width: ${theme.breakpoints.sm}) {
		width: 40vh;
	}
`;

const Textarea = styled.textarea`
	font-family: "Inter", sans-serif;
	outline: none;
	background-color: var(--bg-100);
	width: 60vh;
	padding: 0 1.5rem;
	align-content: center;
	resize: none;
	border: none;
	color: var(--text-400);
	font-size: 0.9rem;

	&:focus {
		outline: none;
	}

	@media screen and (max-width: ${theme.breakpoints.lg}) {
		width: 50vh;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 40vh;
	}
`;

const Icons = styled.div`
	display: flex;
	gap: 0.3rem;
	margin-right: 1rem;

	button {
		outline: none;
		border: none;
		background-color: transparent;
	}
`;

const ArrowIcon = styled(PiNavigationArrowFill)`
	font-size: 1.3rem;
	transform: rotate(45deg);
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	color: ${({ disabled }) => (disabled ? "gray" : "black")};
`;

const Enter = styled.div`
	color: grey;
	display: flex;
	justify-content: flex-end;
	font-size: 0.8rem;
`;
