import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { FaCheck } from "react-icons/fa6";
import { useTheme } from "../ThemeContext";
import { BsDownload } from "react-icons/bs";
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";
import { VscClose } from "react-icons/vsc";
import Checkbox from "./Checkbox";
import NavItems from "./NavItems";
import UserItems from "./UserItems";
import { theme } from "../Theme";

const Directory = () => {
	const { currentTheme, toggleTheme } = useTheme();
	const [isOpen, setIsOpen] = useState(true);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isDownload, setIsDownload] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [filesList, setFilesList] = useState([]);
	const [downloadStatus, setDownloadStatus] = useState(null);
	const [checkboxes, setCheckboxes] = useState({
		benefitsForm: false,
		planDescription: false,
		noticeOfChanges: false,
		showDownloadIcon: true,
		showProposedPrompts: false,
		faqs: false,
		switchToLightMode: false,
	});

	useEffect(() => {
		setIsVisible(null);
		if (downloadStatus === "success" || downloadStatus === "error") {
			setIsVisible(true);

			const timeoutId = setTimeout(() => {
				setIsVisible(false);
				setDownloadStatus(null);
			}, 5000);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [downloadStatus]);

	useEffect(() => {
		if (currentTheme === "light") {
			document
				.querySelector("html")
				.setAttribute("data-theme", "light-dashboard");
		} else {
			document.querySelector("html").removeAttribute("data-theme");
		}
	}, [currentTheme]);

	const toggleBar = () => {
		setIsOpen(!isOpen);
	};

	const updateSidebarState = () => {
		if (window.innerWidth <= 1000) {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	};

	useEffect(() => {
		updateSidebarState();
		window.addEventListener("resize", updateSidebarState);

		return () => {
			window.removeEventListener("resize", updateSidebarState);
		};
	}, []);

	const toggleCheckbox = (checkboxName) => {
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[checkboxName]: !prevCheckboxes[checkboxName],
		}));

		if (checkboxName === "switchToLightMode") {
			toggleTheme();
		}
	};

	useEffect(() => {
		const id = setTimeout(() => {
			if (!filesList.length)
				window.sdkInstance.file.getFileList().then((fileList) => {
					setFilesList(fileList.files);
				});
		}, 1000);
		return () => {
			clearTimeout(id);
		};
	}, [filesList.length]);

	const handleDownload = async (fileKey) => {
		try {
			const fileList = await window.sdkInstance.file.getFileList();

			if (fileList && fileList.files && fileList.files.length > 0) {
				const file = fileList.files.find((f) => f.Key === fileKey);

				if (file) {
					setIsPopupOpen(!isPopupOpen);
					const link = document.createElement("a");
					link.href = file.signedUrl;
					link.download = `${file.Key}.pdf`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setDownloadStatus("success");
					setIsDownload(isDownload);
				} else {
					console.error(`File with title ${fileKey} not found.`);
					setIsDownload("error");
					setDownloadStatus("error");
				}
			} else {
				console.error("Files array is empty or undefined.");
				setIsDownload("error");
				setDownloadStatus("error");
			}
		} catch (error) {
			console.error(error);
			setIsDownload("error");
			setDownloadStatus("error");
		} finally {
			setIsPopupOpen(false);
		}
	};

	return (
		<Div>
			{isOpen ? (
				<SmallContainer>
					<Open onClick={toggleBar}>
						<BsReverseLayoutSidebarInsetReverse />
					</Open>
					<Main>
						<div className="head">
							<h2 className="title">Knowledge Base</h2>
						</div>

						<div>
							<h4 className="title">File History</h4>
							<First>
								{filesList.map((item) => (
									<div key={item.Key} className="main">
										<div
											className="download"
											onClick={() => {
												setIsPopupOpen(true);
												setIsDownload(item.Key);
											}}
										>
											{checkboxes.showDownloadIcon && <BsDownload />}
										</div>
										<FileTitle>
											<ul>
												<li>{item.title}</li>
											</ul>
										</FileTitle>
									</div>
								))}
								<div className="second">
									<Checkbox
										checked={checkboxes.switchToLightMode}
										onChange={() => toggleCheckbox("switchToLightMode")}
										currentTheme={currentTheme}
									/>
								</div>
							</First>
						</div>
						{isPopupOpen && (
							<PopupContainer className="popup">
								<div className="header-container">
									<p>Please confirm your decision</p>
									<p>to download this file.</p>
									<div className="buttons-container">
										<button onClick={() => handleDownload(isDownload)}>
											Confirm and Proceed
										</button>
										<button onClick={() => setIsPopupOpen(false)}>
											Cancel
										</button>
									</div>
								</div>
							</PopupContainer>
						)}
						<StatusContainer isVisible={isVisible}>
							<div>
								{downloadStatus === "success" ? (
									<div>
										Download Successful
										<Check />
									</div>
								) : downloadStatus === "error" ? (
									<div>
										Download Error
										<Close />
									</div>
								) : null}
							</div>
						</StatusContainer>
						<Nav>
							<NavItems />
						</Nav>
						<NavUser>
							<UserItems />
						</NavUser>
					</Main>
				</SmallContainer>
			) : (
				<Open onClick={toggleBar}>
					<BsReverseLayoutSidebarInsetReverse />
				</Open>
			)}
		</Div>
	);
};

export default Directory;

const Div = styled.div`
	background-color: var(--bg-200);
	color: var(--text-100);
	padding: 2rem;
	position: relative;
	right: 0;

	.title {
		font-weight: 400;
		margin-bottom: 1rem;
	}

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}) {
		background-color: var(--bg-300);
		position: absolute;
		align-items: center;
		padding: 0;
	}
`;

const SmallContainer = styled.div`
	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}) {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		position: relative;
		height: 100vh;
		width: 17rem;
		-webkit-box-shadow: -45px 0px 144px 8px rgba(0, 0, 0, 0.95);
		-moz-box-shadow: -45px 0px 144px 8px rgba(0, 0, 0, 0.95);
		box-shadow: -45px 0px 144px 8px rgba(0, 0, 0, 0.95);
	}
`;

const Check = styled(FaCheck)`
	font-size: 0.8rem;
	margin-top: 0.1rem;
	margin-left: 0.15rem;
`;

const Nav = styled.div`
	display: none;

	@media (max-width: ${theme.breakpoints.lg}) {
		display: flex;
		margin-right: 0.5rem;
		border-top: solid var(--text-100) 1px;
		margin-bottom: 2rem;
		padding-bottom: 0.7rem;
		padding-top: 0.4rem;
	}
`;

const NavUser = styled.div`
	display: none;

	@media (max-width: ${theme.breakpoints.lg}) {
		display: flex;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
`;

const Close = styled(VscClose)`
	font-size: 0.8rem;
	margin-top: 0.1rem;
	margin-left: 0.15rem;
	color: var(--txt-500);
`;

const Main = styled.div`
	white-space: nowrap;
	overflow: auto;
	width: ${(props) => (props.isOpen ? "0vh" : "20rem")};
	flex: 1 1 auto;
	flex-wrap: wrap;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);

	.head {
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: ${theme.breakpoints.lg}) {
		width: ${(props) => (props.isOpen ? "0vh" : "20rem")};
		margin: 1rem;
	}

	@media screen and (max-width: ${theme.breakpoints.lg}),
		(max-width: ${theme.breakpoints.md}) {
		width: ${(props) => (props.isOpen ? "0vh" : "15rem")};
	}
`;

const First = styled.div`
	font-size: 0.9rem;

	.main {
		margin-bottom: 1rem;
		border-radius: 0.5rem;
		display: flex;
		padding: 0.8rem;
		border: solid var(--text-100) 1px;
		gap: 1rem;
		overflow-x: hidden;
		width: 20rem;

		.download {
			display: flex;
			cursor: pointer;
		}

		@media screen and (max-width: 937px) {
			width: 15rem;
		}

		@media screen and (max-width: 1100px) {
			width: 14.5rem;
			gap: 0.7rem;
		}
	}

	.second {
		margin-bottom: 1rem;
		display: flex;
		gap: 0.7rem;
	}

	@media screen and (max-width: 937px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 1100px) {
		font-size: 0.8rem;
	}
`;

const FileTitle = styled.div`
	ul {
		width: 20rem;
	}

	li {
		list-style: none;
	}
`;

const Open = styled.button`
	border: none;
	background-color: transparent;
	outline: none;
	border-radius: 0.5rem;
	width: 3rem;
	padding: 0.9rem;
	color: var(--text-100);
	cursor: pointer;
	font-size: 0.9rem;
	position: absolute;
	top: 1.5rem;
	right: 0.4rem;

	&:hover {
		transition-duration: 0.2s;
	}

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		position: relative;
		top: 1rem;
		margin-top: 1rem;
	}
`;

const StatusContainer = styled.div`
	z-index: 100;
	position: fixed;
	top: 4.3rem;
	right: 0;
	width: 15rem;
	background-color: var(--accent-100);
	color: var(--text-500);
	padding: 0.5rem;
	text-align: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	transform: ${({ isVisible }) =>
		isVisible ? "translateX(0)" : "translateX(100%)"};
	animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 3s ease-in
		forwards;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const PopupContainer = styled.div`
	background-color: var(--accent-100);
	color: var(--text-500);
	height: 13rem;
	width: 16rem;
	border-radius: 0.6rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	bottom: 17rem;
	left: 2.4rem;

	.header-container {
		padding: 1rem;
		font-weight: 700;

		p {
			flex-wrap: wrap;
			text-align: center;
		}

		.buttons-container {
			margin-top: 0.8rem;
			display: flex;
			flex-direction: column;
		}

		button {
			margin: 0.3em;
			background-color: var(--bg-200);
			border: none;
			color: var(--text-100);
			padding: 0.8rem;
			border-radius: 0.4rem;
			cursor: pointer;
		}
	}

	@media screen and (max-width: 937px) {
		left: 0.5rem;
		bottom: 17rem;
	}

	@media screen and (max-width: 1100px) {
		left: 0.8rem;
		bottom: 16rem;
		height: 13rem;
		width: 13rem;
		font-size: 0.9rem;
	}
`;
