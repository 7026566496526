const sleep = ms => new Promise(r => setTimeout(r, ms));

export class File {
    constructor(sdkInstance) {
        this.sdkInstance = sdkInstance;
        this.config = sdkInstance.config;
        this.retry = 3;
    }

    async sendFileRequest(request,data) {
        let count = 1;
        let response;
        let responseJson;

        while(count <= this.retry) {
            let response;
            let responseJson;
            try {

                const body = {
                    request: request,
                    data: data || {}
                }
                response = await fetch(`${this.config.backendPath}/api/app/fs/google`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${this.config.authToken}`,
                        'Content-Type': 'application/json'
                    }, 
                    body: JSON.stringify(body)
                });
                if(!response.ok){
                    console.error(`receive error in sendFileRequest - invalid response: not ok: ${JSON.stringify(response)}`);
                    //this.sdkInstance.triggerError('agent', request, `receive error in sendAgentRequest - invalid response: no ok`);
                    //return `receive error in sendAgentRequest - invalid response: no ok`
                } else {

                    responseJson = await response.json();
                    if(!responseJson || !responseJson.status || !responseJson.status == 'ok') {
                        console.error(`receive error in sendFileRequest - invalid response: ${JSON.stringify(responseJson)}`);
                        //this.sdkInstance.triggerError('agent', request, `receive error in sendAgentRequest - invalid response: ${JSON.stringify(responseJson)}`);
                        //return `receive error in sendAgentRequest - invalid response: ${JSON.stringify(responseJson)}`
                    } else {
                        this.sdkInstance.triggerSuccess('file', request, responseJson.data);
                        return responseJson.data;
                    }
                }
            }
            catch(error){
                console.error(`error on sendFileRequest - ${error.message}`);
                //this.sdkInstance.triggerError('agent', request, error.message);
                //return error.message;
            }
            count += 1;
            sleep(2);
            console.log(`retrying fetch`);
        }
        // too many errors - give up
        this.sdkInstance.triggerError('file', request, `receive error in sendAgentRequest - invalid response: ${JSON.stringify(response)}`);
        return;
    }

    async getFileList() {
        const list = await this.sendFileRequest('getFileList');
        return list;
    }

    async getFaqList() {
        const data = await this.sendFileRequest('getFaqList');
        
        console.log(`faqList: ${JSON.stringify(data)}`);
        return data.file;
    }

    async getFilesystem() {
        const system = await this.sendFileRequest('getFilesystem');
        return system;
    }

    async getFile(filename) {
        const data = await this.sendFileRequest('getFile', {filename: filename});
        let fileList = [];
        if(data.file) {
            fileList = data.file.split('\n');
        }
        console.log(`fileList: ${JSON.stringify(fileList)}`);
        return fileList;
    }

     async dumpBatch(name, excludes) {
        await this.sendFileRequest('dumpBatch', {
            name: name,
            excludes: excludes
        });
    }

    async dump() {
        await this.sendFileRequest('dump');
    }
    
}