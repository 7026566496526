import DefaultTheme from "styled-components";

export const dashboardLightTheme = {
	...DefaultTheme,
	/* turquoise */
	"--accent-100": "#03A272",
	/* eerie black */
	"--text-100": "#0a100d",
	/* grey */
	"--text-200": "#767676",
	/* medium purple */
	"--text-300": "#722188",
	"--text-400": "#0a100d",
	"--text-500": "#001219",
	"--text-600": "#E02424",
	/* white smoke */
	"--bg-200": "#fdfffc",
	/* gainsboro */
	"--bg-100": "#D1D5DB",
	/* grey */
	"--bg-300": "#f4f3ee",
	"--bg-400": "#e6e8e6",
	"--bg-500": "#FFF2F2",
};

export const dashboardDarkTheme = {
	...DefaultTheme,
	/* turquoise */
	"--accent-100": "#02ffb3",
	/* white smoke */
	"--text-100": "#f4f3ee",
	/* grey */
	"--text-200": "#8a817c",
	/* medium purple */
	"--text-300": "#BB73CE",
	"--text-400": "#0a100d",
	"--text-500": "#001219",
	"--text-600": "#E02424",
	/* mint cream */
	"--bg-100": " #fdfffc",
	/* eerie black */
	"--bg-200": "#0a100d",
	/* grey */
	"--bg-300": "#333",
	"--bg-400": "#e6e8e6",
	"--bg-500": "#FFF2F2",
};

export const theme = {
	...DefaultTheme,
	breakpoints: {
		xs: "376px",
		sm: "576px",
		md: "786px",
		lg: "992px",
		xl: "1200px",
		xxl: "1400px",
	},
};
