import { Auth }  from './auth.js';
import { Agent } from './agent.js';
import { File }  from './file.js'


function queryStringToJSON(queryString) {
  if(!queryString || typeof queryString != 'string') return;
  if(queryString.startsWith('?')){
    queryString = queryString.split('?')[1];
  }
  var pairs = queryString.split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

export class SDKConfig {
    constructor({
        queryParams,
        backendPath,
        debug
    }) {
        this.queryParams = queryParams;
        this.backendPath = backendPath;
    }
}

export class SDK {
    constructor(config) {
        console.log(`sdk config: ${JSON.stringify(config)}`)
        this.config = config;
        this.config.debug = this.debug;
        this.auth = new Auth(this);
        this.agent = new Agent(this);
        this.file = new File(this);
        this.successCallbacks = [];
        this.errorCallbacks = [];
        if(this.config.queryParams) {
            const query = typeof this.config.queryParams == 'string' ? this.config.queryParams
                    : this.config.queryParams.search;
            this.config.queryParams = queryStringToJSON(query);
        }
    }

    async start() {
        await this.auth.start();
    }

    registerSuccessCallback(categories, callback) {
        this.successCallbacks.push({
            categories,
            callback
        });
    }

    registerErrorCallback(categories, callback) {
        this.errorCallbacks.push({
            categories,
            callback
        });
    }

    triggerSuccess(category, name, data) {
        if(this.config.debug) console.debug(`sdk triggerSuccess ${category} ${name}: ${JSON.stringify(data)}`);
        for(let callbackData of this.successCallbacks) {
            if(!callbackData.categories.includes(category)) continue;
            callbackData.callback(category, name, data);
        }
    }

    triggerError(category, name, data) {
        console.debug(`sdk triggerError ${category} ${name}: ${JSON.stringify(data)}`);
        for(let callbackData of this.errorCallbacks) {
            if(!callbackData.categories.includes(category)) continue;
            callbackData.callback(category, name, data);
        }
    }

    // auth wrapper methods
    async login(username, password, client) {
        client = client ? client : this.config.queryParams?.client;
        console.log(`login request: ${username} ${password} ${client}`);
        return await this.auth.login(username, password, client);
    }

    async logout() {
        return await this.auth.logout();
    }

    async disclaimer(status) {
        return await this.auth.disclaimer(status);
    }

    oauthClientLoginRequest(clientName) {
        return this.auth.oauthClientLoginRequest(clientName);
    }
}