import React from "react";
import styled from "styled-components";
import AI from "../../assets/AI.png";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const ChatHistoryId = ({ conversation, goBack }) => {
	return (
		<Container>
			<MessagesContainer>
				<Link to="#" onClick={goBack} className="link">
					<BiLeftArrowAlt />
				</Link>
				{conversation.messages.map((message, i) => {
					const isUser = message.type === "user";
					const isAssistant = message.type === "assistant";
					const formattedTime = dayjs(message.created_at).format(
						"h:mm:ss A, D MMM"
					);

					return (
						<div key={i}>
							{isUser && (
								<UserMessageContainer>
									<UserMessage style={{ color: "var(--text-200)" }}>
										<Timestamp>{formattedTime}</Timestamp>
										<p>{message?.content}</p>
									</UserMessage>
									<GradientImage />
								</UserMessageContainer>
							)}
							{isAssistant && (
								<AgentMessage style={{ color: "var(--text-100)" }}>
									<AssistantImage src={AI} alt="assistant" />
									<div>
										<Timestamp>{formattedTime}</Timestamp>
										{message?.content.split("\n").map((paragraph, index) => (
											<p key={index}>{paragraph}</p>
										))}
									</div>
								</AgentMessage>
							)}
						</div>
					);
				})}
			</MessagesContainer>
		</Container>
	);
};

export default ChatHistoryId;

const Container = styled.div`
	width: 60vh;
	height: 70vh;
	margin: 1.5rem auto;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const MessagesContainer = styled.div`
	margin-top: 20px;

	.link {
		font-size: 1.3rem;

		&:hover {
			color: var(--accent-100);
		}
	}
`;

const Timestamp = styled.p`
	font-size: 0.3rem;
	color: var(--text-200);
	margin-bottom: 0.5rem;
`;

const UserMessage = styled.div`
	color: var(--text-300);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	p {
		font-size: 0.9rem;
		line-height: 1.2;
		margin-bottom: 0.9rem;
	}
`;

const UserMessageContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

const GradientImage = styled.div`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
	position: relative;
	top: 1rem;
	background: linear-gradient(5deg, lightblue, turquoise);
`;

const AgentMessage = styled.div`
	color: var(--text-100);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;

	p {
		font-size: 1rem;
		line-height: 1.5;
		margin-bottom: 0.9rem;
	}

	img {
		height: 5vh;
		width: 5vh;
	}
`;

const AssistantImage = styled.img`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
	margin-right: 1rem;
`;
