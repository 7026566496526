import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { theme } from "../Theme";

const UserItems = () => {
	const name = localStorage.getItem("config");
	const config = name ? JSON.parse(name) : null;
	const username = config && config.userData ? config.userData.username : null;
	const navigate = useNavigate();
	const [loggingOut, setLoggingOut] = useState(false);

	const handleLogout = async () => {
		try {
			setLoggingOut(true);
			await window.sdkInstance.logout();
			localStorage.removeItem("config");
			navigate("/");
		} catch (error) {
			console.error("Error during logout:", error);
		} finally {
			setLoggingOut(false);
		}
	};

	return (
		<NavMenu>
			<NavItem>
				{username && (
					<div>
						<p>{username}</p>
					</div>
				)}
			</NavItem>
			<NavItem>
				<button onClick={handleLogout} disabled={loggingOut}>
					Logout
				</button>
			</NavItem>
		</NavMenu>
	);
};

export default UserItems;

const NavMenu = styled.div`
	display: flex;

	@media (max-width: ${theme.breakpoints.lg}) {
		flex-direction: row;
		padding: 1rem;
		gap: 6rem;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		flex-direction: row;
		padding: 1rem;
		gap: 4rem;
	}
`;

const NavItem = styled.div`
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	font-size: 0.9rem;

	.upgrade {
		color: var(--accent-100);
		font-size: 0.9rem;
	}

	.grey {
		color: var(--text-200);
	}

	.help {
		color: var(--text-300);
		cursor: pointer;
	}

	button {
		background-color: transparent;
		color: var(--text-200);
		border: none;
		cursor: pointer;
	}
`;
